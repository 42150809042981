import React, { useState, useRef, useEffect } from 'react'
import { PopupModal } from "react-calendly";

const Nav = ()  => {

  var link = "https://calendly.com/itstimbunyan/30min";
  const [call, setCall] = useState(false);

  let [open,setOpen] = useState(false);

  const [navBg, setNavBg] = useState(false);

  const changeNavBg = () => {
    window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNavBg);
    return () => {
      window.removeEventListener('scroll', changeNavBg);
    }
  }, [])

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  return (
    <div className='w-full fixed top-0 left-0 z-40 '>
      <div  onScroll={changeNavBg} className={` ${navBg ?  'bg-white shadow-md' : ''} ${isMobile ? 'bg-white shadow-md' : ''} tablet:flex items-center justify-between py-4 2xl:pl-72 pl-6 2xl:pr-72 xl:pl-32 xl:pr-32 lg:pl-6 lg:pr-6 ${isMobile ? ' text-black' : 'text-white'} transition-all duration-300`}>
        <div>
        <h1 className={` ${isMobile ? ' text-black' : navBg ? 'text-black' : 'text-white'} lg:text-xl font-medium mt-auto mb-auto w-[256px]`}>Tim Bunyan</h1>

        
        <div onClick={()=>setOpen(!open)} className={`text-3xl absolute right-8 top-[14px] cursor-pointer  tablet:hidden ${isMobile ? ' text-black' : navBg ? 'text-black' : 'text-white'} `}>
          <ion-icon name={open ? 'close':'menu'}></ion-icon>
        </div>
      </div>

      <ul className={`${navBg ?  'text-black' : ''}  tablet:flex tablet:items-center tablet:pb-0 pb-12 absolute tablet:static ${navBg ? 'text-black' : ''}  tablet:z-auto z-[-1] left-0 w-full tablet:w-auto tablet:pl-0 pl-9 transition-all duration-300 ease-in ${open ? 'top-15 bg-white text-black ':'top-[-490px]'}`}>
        
        <li className='tablet:ml-6 text-base  tablet:my-0 my-7 hover:cursor-pointer'>
          <a href={undefined} className={`hover:text-[#FF6B6B] hover:cursor-pointer`} onClick={() =>  window.scrollTo({top:  0, behavior: "smooth"})}>Home</a>
        </li>
        <li className='tablet:ml-6 text-md tablet:my-0 my-7 hover:cursor-pointer'>
          <a href={undefined} onClick={() => {
              if(window.innerWidth < 1020){
                  window.scrollTo({top:  3400, behavior: "smooth"});
              } else {
                  window.scrollTo({top: 2050, behavior: "smooth"});
              }
          }}  className={`hover:text-[#FF6B6B] hover:cursor-pointer`}>About Me</a>
        </li>
        <li className='tablet:ml-6 text-md  tablet:my-0 my-7 hover:cursor-pointer'>
          <a href={undefined}  className={`hover:text-[#FF6B6B] hover:cursor-pointer`} onClick={() => {
              if(window.innerWidth < 1020){
                  window.scrollTo({top: 7700, behavior: "smooth"});
              } else {
                  window.scrollTo({top: 5700, behavior: "smooth"});
              }
          }} >Testimonials</a>
        </li>
        <li className='tablet:ml-6 text-md tablet:my-0 my-7 hover:cursor-pointer'>
          <a href={undefined} onClick={() => {
              if(window.innerWidth < 1020){
                  window.scrollTo({top: 4550, behavior: "smooth"});
              } else {
                  window.scrollTo({top: 3050, behavior: "smooth"});
              }
          }}  className={`hover:text-[#FF6B6B] hover:cursor-pointer`}>Why Choose Me</a>
        </li>

        <li className='lg:hidden flex'>
          <a onClick={() => setCall( true )} className="button text-white pl-8 pr-8 pt-3 pb-3 lg:ml-6 rounded-xl bg-gradient-to-r bg-[#FF6B6B] hover:bg-[#fe8080] w-[256px] lg:w-auto flex transition-all hover:cursor-pointer" href={undefined}>Free Consultation Call
            <img src="./images/arrow.png" alt="arrow" width="32px" height="32px" className='w-4 ml-3 mt-auto mb-auto'/>
          </a>
        </li>
      </ul>
      <a onClick={() => setCall( true )} className="lg:flex hidden button text-white pl-8 pr-8 pt-3 pb-3 lg:ml-6 rounded-xl bg-gradient-to-r bg-[#FF6B6B] hover:bg-[#fe8080] w-[256px] lg:w-auto transition-all hover:cursor-pointer" href={undefined}>Free Consultation Call
            <img src="./images/arrow.png" alt="arrow" width="32px" height="32px" className='w-4 ml-3 mt-auto mb-auto'/>
          </a>
      </div>
      <PopupModal
                                url={link}
                                onModalClose={() => setCall( false )}
                                open={call}
                                rootElement={document.getElementById("root")}
                            />
    </div>
  )
}

export default Nav 