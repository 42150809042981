import React, {useState, useEffect} from 'react'
import beach1File from "../assets/beach1.mp4"
import yashVideo from "../assets/yash.mp4"
import "../../node_modules/video-react/dist/video-react.css";
import Form from 'react-bootstrap/Form';
import { PopupModal } from "react-calendly";

import { Player, ControlBar } from 'video-react';
import CalendlyEmbed from './CalendlyEmbed';

export default function Main() {

    var link = "https://calendly.com/itstimbunyan/30min";

    const [call, setCall] = useState(false);
    const [domLoaded, setDomLoaded] = useState(false);

    
    useEffect(() => {
        setDomLoaded(true);
    }, []);

    return(
        <div className='max-w-[1920px] ml-auto mr-auto overflow-hidden'>
            
            <video controls={false} defer width="1920px" height="1080px" autoPlay loop muted className='absolute z-minus brightness-40 w-[1920px] md:h-screen h-[102vh] object-cover nature' src={beach1File}/>
            <div className='2xl:ml-44 2xl:mr-44 xl:ml-40 xl:mr-40 pl-6 pr-6 h-screen lg:inline-flex flex lg:flex-row flex-col lg:pt-0 pt-24'>

                <div className='mt-auto mb-auto lg:pt-[80px]'>
                    <h1 className='font-bold 2xl:text-5xl xl:text-4xl lg:text-3xl text-2xl text-blue-main lg:h-32 inline-block'>I'll Help You Build the Relationships, Self-Growth, & Success You've Always Dreamed Of
                    </h1>
                    <h1 className='xl:text-xl text-lg mt-6 text-blue-main'>Living on autopilot every day fearing that you'll miss out on life's magic? Together, we'll reclaim your joyful and purposeful life and take back the fulfillment that has been lost.</h1>
                    <button onClick={() => setCall( true )} className='bg-[#FF6B6B] hover:bg-[#f87c7c] transition-all hover:scale-105 text-white mt-6 xl:pl-7 flex xl:pr-7 xl:pt-3 xl:pb-3 pt-3 pb-3 pl-5 pr-5 rounded-xl 2xl:text-lg'>Book a Free Consultation Call
                        <img src="./images/arrow.png" alt="arrow" width="32px" height="32px" className='w-4 ml-3 mt-auto mb-auto'/>
                    </button>
                    <PopupModal
                                url={link}
                                onModalClose={() => setCall( false )}
                                open={call}
                                rootElement={document.getElementById("root")}
                            />
                    <div className='flex mt-8'>
                        <img src="./images/starsreviews.png" className='rounded-full 3xl:w-[30%] xl:w-[40%] lg:w-[45%] w-[60%] lg:max-w-none max-w-[240px]'/>
                    </div>
                </div>
                <img src="./images/herotim4.webp" alt="Tim Bunyan" className=' self-end 2xl:h-[90%] xl:h-[80%] lg:h-[90%] lg:w-auto md:w-[33.8%] sm:w-[46%] smm:w-[53%] smmm:w-[58%] sm320:w-[73%] ml-auto mr-auto lg:ml-0 lg:mr-0 object-contain '/>

            </div>

            <div>
                <h1 className='lg:text-xl text-lg text-center pl-6 pr-6 lg:pl-0 lg:pr-0 mt-6 mb-3 font-medium bg-blue-main pt-3 pb-3'>I've helped 9+ people transform their lifes</h1>
            </div>

            <div className='pb-32 2xl:mt-28 lg:mt-24 mt-16'>
                {/* <h1 className='font-bold 2xl:text-4xl lg:text-3xl text-2xl text-center xl:pl-[30%] xl:pr-[30%] lg:pl-[22%] lg:pr-[22%] pl-6 pr-6'>Are You Feeling Stuck or Lost in Your Journey of Self-Development?</h1> */}
                <h1 className='font-bold 2xl:text-4xl lg:text-3xl text-2xl text-center xl:pl-[30%] xl:pr-[30%] lg:pl-[22%] lg:pr-[22%] pl-6 pr-6'>Are You Feeling Stuck or Lost in Life?</h1>
                <h1 className='xl:text-xl text-base 2xl:mt-16 lg:mt-12 mt-8 text-center 2xl:pl-[26%] 2xl:pr-[26%] xl:pl-[25%] xl:pr-[25%] lg:pl-[20%] lg:pr-[20%] pl-6 pr-6'>Many of us aspire to grow and become better versions of ourselves, but it's not always easy to know where to start or how to overcome the hurdles that stand in our way.
                    <br></br>If you're a young man looking to make a change in your life, you might be experiencing:</h1>
                <div className='lg:flex 2xl:ml-44 2xl:mr-44 xl:ml-32 xl:mr-32 lg:ml-16 lg:mr-16 mt-12 text-center justify-between pl-6 pr-6 lg:pl-0 lg:pr-0'>
                   <div className='lg:w-[18%] mt-12 lg:mt-0'>
                        <img src="./images/wonder.jpg" className='w-[60%] ml-auto mr-auto lg:ml-0 lg:mr-0 lg:w-auto'/>
                        <h1 className='2xl:mt-3 mt-5 2xl:text-xl text-base pl-6 pr-6 lg:pl-0 lg:pr-0'>Uncertainty about how to start the journey of self-development and where to find the right path.</h1>
                    </div> 
                   <div className='lg:w-[18%] mt-6 lg:mt-0'>
                        <img src="./images/maze2.jpg" className='w-[60%] ml-auto mr-auto lg:ml-0 lg:mr-0 lg:w-auto'/>
                        <h1 className='2xl:mt-3 mt-5 2xl:text-xl text-base pl-6 pr-6 lg:pl-0 lg:pr-0'>Frustration because every day feels the same. The same loop plays out daily but no progress is being made.</h1>
                    </div> 
                   <div className='lg:w-[18%] mt-14 lg:mt-0'>
                        <img  src="./images/lost.png" className='w-[60%] ml-auto mr-auto lg:ml-0 lg:mr-0 lg:w-auto'/>
                        <h1 className='2xl:mt-3 mt-5 2xl:text-xl text-base pl-6 pr-6 lg:pl-0 lg:pr-0'>Lack of direction and clarity in your personal and professional life. There's no clarity on what path you should take.</h1>
                    </div> 
                   <div className='lg:w-[18%] mt-14 lg:mt-0'>
                        <img  src="./images/sad.png" alt="sad" className='w-[60%] ml-auto mr-auto lg:ml-0 lg:mr-0 lg:w-auto'/>
                        <h1 className='2xl:mt-3 mt-5 2xl:text-xl text-base pl-6 pr-6 lg:pl-0 lg:pr-0'>Fears and negativity that are holding you back, while "what ifs" and "you're not good enoughs" are ruining your self confidence.</h1>
                    </div> 
                </div>
            </div>
            <div className='bg-blue-main'>
                <h1 className='text-center xl:pl-[28%] xl:pr-[28%] lg:pl-24 lg:pr-24 pl-6 pr-6 xl:text-xl pt-10 pb-10 font-medium text-lg'>But here's the good news: You don't have to stay lost. I know what it's like having those doubts and frustrations because I've been there.
                I'll help you overcome them and transform your life for the better.</h1>
            </div>
            <div className=' overflow-hidden 2xl:pb-32 lg:pb-24 pb-16'>
                <h1 className='font-bold 2xl:text-4xl lg:text-3xl text-2xl text-center xl:pl-[30%] xl:pr-[30%] lg:pl-[20%] lg:pr-[20%] pl-6 pr-6 2xl:mt-32 lg:mt-24 mt-16'>Start and Better Your Journey of Personal Growth and Self-Development</h1>
                <div className='lg:flex lg:mt-16 mt-12 2xl:ml-72 xl:ml-32 lg:ml-12 xl:text-xl text-base pl-6 pr-6 lg:pl-0 lg:pr-0 text-center lg:text-left'>
                    <div className='lg:w-[43%] lg:pr-12 mt-auto mb-auto'>
                        <h1>
                            Hello, I'm Tim Bunyan, and I'm here to guide you on your path to personal growth and self-discovery.
                            <br></br><br></br>
                            I understand the challenges of transforming your life because I've been there myself. My life experiences have shaped me into the 
                            person I am today, and I'm passionate about helping others overcome obstacles, break through limitations, and unlock their true 
                            potential.
                            <br></br><br></br>
                            I believe that self-development is a lifelong journey, and I'm excited to be your companion as you take your first steps or navigate 
                            the road ahead. Together, we'll embark on a transformational journey that will empower you to live a more fulfilling and purposeful 
                            life.
                        </h1>
                        <button onClick={() => setCall( true )} className='bg-[#FF6B6B] hover:bg-[#f87c7c] transition-all hover:scale-105 ml-auto mr-auto lg:ml-0 lg:mr-0 text-white flex mt-6 xl:pl-7 xl:pr-7 xl:pt-3 xl:pb-3 pt-3 pb-3 pl-5 pr-5 rounded-xl 2xl:text-lg'>Book a Free Consultation Call
                            <img src="./images/arrow.png" alt="arrow" width="32px" height="32px" className='w-4 ml-3 mt-auto mb-auto'/>
                        </button>
                    </div>
                    <img src="./images/tim2.png" alt="tim" className='mt-6 lg:mt-0 object-contain ml-auto mr-auto lg:ml-0 lg:mr-0 xl:w-auto lg:w-[50%]'/>
                </div>
            </div>

            <div className='2xl:pt-32 lg:pt-24 lg:pb-24 pt-16 2xl:pb-32 pb-16 bg-blue-main 3xl:pl-72 3xl:pr-72 2xl:pl-44 2xl:pr-44 xl:pl-24 xl:pr-24 lg:pl-12 lg:pr-12'>
                <div className='lg:flex pl-6 pr-6'>
                    <img src="./images/unlock2.png" alt="unlock" className='2xl:w-[43%] xl:w-[39%] lg:w-[40%] object-contain hidden lg:block'/>
                    <div className='lg:ml-24 xl:text-xl text-base'>
                        <h1 className='font-bold 2xl:text-4xl lg:text-3xl text-2xl text-center lg:text-left lg:ml-0 lg:mr-0 ml-6 mr-6'>Unlock Your Potential with Tim</h1>
                        <p className='mt-8 text-center lg:text-left'>
                            What sets me apart as your self-development coach and mentor is the depth of my life experiences. I've faced the trials and tribulations of personal growth head-on, and I've emerged stronger, wiser, and more resilient.
                            <br></br><br></br>
                            My journey has equipped me with insights and strategies to help you:
                        </p>
                        <div className='flex mt-6'>
                            <img src="./images/1.png" className='lg:w-[8%] w-[12%] object-contain'/>
                            <p className='mt-auto mb-auto ml-3 2xl:w-[55%]'>Overcome obstacles and fears that have been holding you back.</p>
                        </div>
                        <div className='flex mt-6'>
                            <img src="./images/2.png" className='lg:w-[8%] w-[12%] object-contain'/>
                            <p className='mt-auto mb-auto ml-3 2xl:w-[60%]'>Gain clarity about your goals and create a roadmap for your future.</p>
                        </div>
                        <div className='flex mt-6'>
                            <img src="./images/3.png" className='lg:w-[8%] w-[12%] object-contain'/>
                            <p className='mt-auto mb-auto ml-3 2xl:w-[65%]'>Build the confidence and mindset needed to succeed in your personal and professional life.</p>
                        </div>
                        <div className='flex mt-6'>
                            <img src="./images/4.png" className='lg:w-[8%] w-[12%] object-contain'/>
                            <p className='mt-auto mb-auto ml-3 2xl:w-[55%]'>Develop the skills and habits that lead to lasting, positive change.</p>
                        </div>
                        <div className='flex justify-center mb-12 lg:block lg:mb-0'>
                            <button onClick={() => setCall( true )} className='bg-[#FF6B6B] hover:bg-[#f87c7c] transition-all flex hover:scale-105 text-white mt-10 xl:pl-7 xl:pr-7 xl:pt-3 xl:pb-3 pt-3 pb-3 pl-5 pr-5 rounded-xl 2xl:text-lg '>Book a Free Consultation Call
                                <img src="./images/arrow.png" alt="arrow" width="32px" height="32px" className='w-4 ml-3 mt-auto mb-auto'/>
                            </button>
                        </div>
                        <img src="./images/unlock.png" className='lg:w-[43%] object-contain lg:hidden'/>

                    </div>
                </div>
            </div>

            <div className='3xl:ml-[25%] 3xl:mr-[25%] 2xl:ml-72 2xl:mr-72 xl:ml-32 xl:mr-32 lg:ml-12 lg:mr-12 pl-6 pr-6 lg:pb-28 pb-16 lg:mt-24 mt-16'>
                <div className='flex lg:flex-row flex-col-reverse'>
                    <div className=' xl:text-xl text-base mt-12 lg:mt-auto mb-auto lg:mr-24 text-center lg:text-left'>
                        <h1 className='2xl:text-4xl lg:text-3xl text-2xl font-bold'>Get a Free Copy of My E-Book</h1>
                        <h1 className='mt-8'><span className=' font-semibold'>Level Up Your Mindset: Unleash Your Potential For Success </span> is an introductory to the growth mindset and includes tips that will help you adapt one of your own.</h1>
                        <h1 className='mt-3'>I've decided to give this out for free because people, especially young men, nowadays struggle to overcome mental barriers and unlock their full potential.</h1>
                        <h1 className='mt-3'>Practical exercises included.</h1>
                    
                        <button onClick={() => window.open('https://timbunyan.ck.page/21fb6bb3e5','_blank')} className='bg-[#FF6B6B] hover:bg-[#f87c7c] transition-all flex hover:scale-105 ml-auto mr-auto lg:ml-0 lg:mr-0 text-white mt-6 xl:pl-7 xl:pr-7 xl:pt-3 xl:pb-3 pt-3 pb-3 pl-5 pr-5 rounded-xl 2xl:text-lg '>Get a Free Copy
                            <img src="./images/arrow.png" alt="arrow" width="32px" height="32px" className='w-4 ml-3 mt-auto mb-auto'/>
                        </button>
                    </div>
                    <img src="./images/book_cover.jpg" className='2xl:w-[25%] lg:w-[35%] w-[65%] ml-auto mr-auto lg:ml-0 lg:mr-0'/>
                </div>
            </div>

            <div className='relative  2xl:pt-32 2xl:pb-32 lg:pb-24 lg:pt-24 pb-16 pt-16 gradient-fade'>
                <h1 className='font-bold 2xl:text-4xl lg:text-3xl text-2xl text-center  pl-6 pr-6'>Let Me Help You Turn Your Life Around</h1>
                <p className='xl:text-xl text-base text-center lg:mt-6 mt-4 2xl:ml-[27%] 2xl:mr-[27%] lg:ml-[20%] lg:mr-[20%] ml-6 mr-6'>Create a life that you love and are passionate about using my 1:1 coaching program. I use proven methods to support you to reach your goals and a happier, more fulfilling life.</p>
                <p className='xl:text-xl text-base font-bold text-center lg:mt-6 mt-4'>The coaching program includes:</p>
                <div className='bg-[#C7F2F9] border-white border-[4px] 2xl:ml-[25%] 2xl:mr-[25%] lg:ml-[17%] lg:mr-[17%] ml-6 mr-6 lg:mt-12 mt-8 rounded-xl lg:p-12 p-8 lg:pt-20 lg:pb-20 flex flex-col justify-center items-center'>
                    <div className='lg:text-lg'>
                        <div className='flex'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>10 90-minute coaching sessions</p>
                        </div>
                        <div className='flex mt-4'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>Daily accountability to make sure you stay on track</p>
                        </div>
                        <div className='flex mt-4'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>Daily rituals and practices you can do to enhance your life (daily routines)</p>
                        </div>
                        <div className='flex mt-4'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>Weekly assignments and goals with a plan on how to achieve them</p>
                        </div>
                        <div className='flex mt-4'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>Develop skills and areas that you lack in</p>
                        </div>
                        <div className='flex mt-4'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>A way to understand yourself better</p>
                        </div>
                        <div className='flex mt-4'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>Learn how to manage relationships with others and yourself</p>
                        </div>
                        <div className='flex mt-4'>
                            <img src="./images/check.png" className='w-8 object-contain'/>
                            <p className='ml-2 mt-auto mb-auto'>Social media help (if applicable)</p>
                        </div>
                    </div>
                </div>
                <img className='absolute top-0 left-0 z-[-1] 3xl:w-auto xl:w-[70%] lg:w-[80%]' src="./images/topleft.png"/>
                <img className='absolute bottom-0 right-0 z-[-1] 3xl:w-auto lg:w-[80%]' src="./images/bottomright.png"/>
            </div>

            <div className='bg-[#f6f6f6] 2xl:pt-32 2xl:pb-32 lg:pb-24 lg:pt-24 pb-16 pt-16'>
                <h1 className='font-bold 2xl:text-4xl lg:text-3xl text-2xl text-center xl:pl-[30%] xl:pr-[30%] lg:pl-[22%] lg:pr-[22%] pl-6 pr-6'>
                    But Don't Take My Word For It. Check Out What Some of My Clients Have Said
                </h1>
                <div className='w-screen justify-center items-center flex lg:pl-0 lg:pr-0 pl-6 pr-6'>
                    <div className='mt-16 lg:ml-32 lg:mr-32 lg:w-[40%]'>
                        <Player src={yashVideo}>
                            <ControlBar autoHide={false} className="my-class"/>
                        </Player>
                        <div className='bg-blue-main pt-6 pb-8 rounded-b-lg'>
                            <h1 className='lg:text-2xl text-xl font-bold text-center'>Yashdeep Katta</h1>
                            <hr className=' border-black mt-3 lg:ml-44 lg:mr-44 ml-12 mr-12'></hr>
                            <h1 className='mt-3 font-medium text-center lg:text-lg lg:pl-16 lg:pr-16 pl-6 pr-6'>"You've made a real impact in my self-improvement journey. I've came to you several times for some help
                            and you've always made me heared. You're an awesome mentor. Thank you for being part of my life."</h1>

                        </div>
                    </div>
                </div>
                <div className='mt-16 lg:ml-32 lg:mr-32 lg:flex lg:pl-0 lg:pr-0 pl-6 pr-6'>

                    <img src="./images/test4.jpg" className='lg:w-[30%] w-[80%] lg:ml-12 object-contain rounded-lg ml-auto mr-auto'/>
                    <img src="./images/test2.jpg" className='lg:w-[30%] lg:h-[30%] w-[80%] mt-6 lg:mt-0 lg:ml-12 rounded-lg ml-auto mr-auto'/>

                    <div className='lg:w-[30%] w-[80%] lg:pl-10 ml-auto mr-auto'>
                        <img src="./images/test3.jpg" className='rounded-lg mt-6 lg:mt-0'/>
                        <img src="./images/test1.jpg" className='rounded-lg mt-6 '/>
                    </div>
            </div>

            </div>

            <div className='2xl:w-[1440px] bg-blue-main rounded-3xl 2xl:ml-auto 2xl:mr-auto lg:ml-24 lg:mr-24 ml-6 mr-6 w-auto 2xl:p-20 lg:p-12 p-6 shadow-2xl lg:flex mt-24 mb-24'>
                <div className='lg:w-[52%] h-full mr-6 justify-between'>
                    <div>
                        <h2 className='font-bold 2xl:text-4xl lg:text-3xl text-2xl lg:mt-12 mt-6  uppercase lg:w-[700px] xl:w-[600px]'>Ready to take the first step on your self-development journey? Let's start this transformation together.</h2>
                        {/* <p className='mt-3 lg:text-xl text-lg'> </p> */}
                        <p className='mt-3 font-medium lg:text-xl text-lg'>With my guidance, you'll:</p>
                        <div className='flex mt-3'>
                            <img src="./images/check.png" className='lg:w-8 lg:h-8 w-6 h-6 object-contain mt-auto mb-auto'/>
                            <p className='mt-auto mb-auto lg:text-xl ml-3'>Have a trusted partner to support you every step of the way.</p>
                        </div>
                        <div className='flex mt-3'>
                            <img src="./images/check.png" className='lg:w-8 lg:h-8 w-6 h-6 object-contain mt-auto mb-auto'/>
                            <p className='mt-auto mb-auto lg:text-xl ml-3'>Turn your dreams of self-development into a reality.</p>
                        </div>
                        <div className='flex mt-3'>
                            <img src="./images/check.png" className='lg:w-8 lg:h-8 w-6 h-6 object-contain mt-auto mb-auto'/>
                            <p className='mt-auto mb-auto lg:text-xl ml-3'>Live a life that's aligned with your true aspirations and potential.</p>
                        </div>
                        <p className='lg:text-xl lg:mt-12 mt-6'>The first call is free. You'll get some steps that can kick start your journey and we'll explore options to work together.</p>
                    </div>
                    {/* <p className='text-white mt-auto lg:text-xl items-end align-bottom'>The first call is a free audit. You aren't obligated to do anything. You'll get free 
                    help on how to improve or start your landing page or website.</p> */}
                </div>
                {domLoaded && <CalendlyEmbed url={link}/>}
            </div>

            {/* <div className='bg-blue-dark'>
                <h1 className='text-center text-white xl:pl-[28%] xl:pr-[28%] lg:pl-24 lg:pr-24 pl-6 pr-6 xl:text-xl text-lg pt-10 pb-10 font-medium'>With my guidance, you'll have a trusted partner to support you every step of the way. Together, we'll turn your dreams of self-development into a reality, helping you live a life that's aligned with your true aspirations and potential.</h1>
            </div>
            <div className='bg-white lg:pt-32 lg:pb-32 pt-16 pb-16'>
                <div className='bg-blue-main rounded-xl xl:pl-44 xl:pr-44 lg:pl-12 lg:pr-12  2xl:ml-72 2xl:mr-72 lg:ml-32 lg:mr-32 ml-6 mr-6 lg:pt-24 lg:pb-24 pt-16 pb-16 items-center justify-center flex flex-col'>
                    <h1 className='font-bold 2xl:text-4xl lg:text-3xl text-center 2xl:pl-[13%] 2xl:pr-[13%] pl-14 pr-14'>Ready to take the first step on your self-development journey? Let's start this transformation together.</h1>
                    <button onClick={() => setCall( true )} className='bg-[#FF6B6B] hover:bg-[#f87c7c] transition-all hover:scale-105 flex text-white mt-10 xl:pl-7 xl:pr-7 xl:pt-3 xl:pb-3 pt-3 pb-3 pl-5 pr-5 rounded-xl 2xl:text-lg'>Book a Free Consultation Call
                        <img src="./images/arrow.png" alt="arrow" width="32px" height="32px" className='w-4 ml-3 mt-auto mb-auto'/>
                    </button>

                </div>
            </div> */}


            <div className='2xl:pt-32 2xl:pb-24 pt-16 pl-6 pr-6 2xl:pl-44 2xl:pr-44 lg:flex justify-center bg-blue-main'>

                <div className='lg:w-[25%]'>
                    <h1 className='text-3xl font-bold'>Tim Bunyan</h1>
                    <h1 className='text-xl  mt-3'>Your friend and guide on your path to personal growth and self-discovery.</h1>
                    <img src="./images/twitter.png" loading="lazy" decoding="async" alt="Twitter" width="40px" height="32px" class="lg:w-10 w-8 mt-4 hover:cursor-pointer" onClick={() => window.open('https://twitter.com/ItsTimBunyan')}/>

                </div>

                <div>
                <ul className='lg:ml-10 mt-6 lg:mt-0'>
                    <li class="font-semibold text-2xl" href={undefined}>Menu</li>
                    <li className='mt-2 lg:text-xl hover:text-blue-dark text-lg hover:cursor-pointer'><a onClick={() => {
                        if(window.innerWidth < 1020){
                            window.scrollTo({top: 900, behavior: "smooth"});
                        } else {
                            window.scrollTo({top: 950, behavior: "smooth"});
                        }
                    }} href={undefined}>Who I can help</a></li>
                    <li className='mt-1 lg:text-xl hover:text-blue-dark text-lg hover:cursor-pointer'><a onClick={() => {
                        if(window.innerWidth < 1020){
                            window.scrollTo({top: 2950, behavior: "smooth"});
                        } else {
                            window.scrollTo({top: 2050, behavior: "smooth"});
                        }
                    }} href={undefined}>About me</a></li>
                    <li className='mt-1 lg:text-xl hover:text-blue-dark text-lg hover:cursor-pointer'><a onClick={() => {
                        if(window.innerWidth < 1020){
                            window.scrollTo({top: 6100, behavior: "smooth"});
                        } else {
                            window.scrollTo({top: 4800, behavior: "smooth"});
                        }
                    }} href={undefined}>Testimonials</a></li>
                    <li className='mt-1 lg:text-xl hover:text-blue-900 text-lg hover:cursor-pointer'><a onClick={() => {
                        if(window.innerWidth < 1020){
                            window.scrollTo({top: 4100, behavior: "smooth"});
                        } else {
                            window.scrollTo({top: 3000, behavior: "smooth"});
                        }
                    }} href={undefined}>Why choose me</a></li>
                    <li className='mt-1 lg:text-xl hover:text-blue-900 text-lg hover:cursor-pointer'><a onClick={() => window.open('https://twitter.com/ItsTimBunyan')} href="javascript:;">Twitter</a></li>
                </ul>
                </div>

                <div>
                    <div className='lg:ml-16 mt-6 lg:mt-0'>
                    <h1 class=" font-semibold text-2xl w">Subscribe</h1>
                    <h1 className='mt-2 lg:text-xl text-lg 2xl:w-[400px] lg:w-[300px] mr-6 lg:mr-0'>Join my newsletter for weekly tips on how to improve your self-development journey.</h1>
                    <script src="https://f.convertkit.com/ckjs/ck.5.js"/>
                    <Form action="https://app.convertkit.com/forms/5773975/subscriptions" method="post" data-sv-form="5773975" data-uid="59cd9c51d4" data-format="modal" data-version="5" 
                    data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">
                        <div data-style="clean"><ul class="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul><div data-element="fields" 
                        data-stacked="false" class="seva-fields formkit-fields">
                            <div className='flex mt-4'>
                                <div class="formkit-field">
                                <input class="formkit-input" name="email_address" aria-label="Email Address" required={true} placeholder="Email Address" type="email"
                                    className='pt-4 pb-4 pl-4 lg:w-60 w-[210px]   text-sm bg-blue-dark placeholder:text-white rounded-d rounded-l-md'/>
                                </div>
                                <button data-element="submit" class="formkit-submit formkit-submit" className='w-[50px] pl-4 h-[52px] pr-4 mt-auto mb-auto bg-blue-dark rounded-r-md' aria-label="Send">
                                <img src="./images/arrow.png" alt="arrow" width="32px" height="32px"/>
                                </button>
                                </div>
                            </div>

                        </div>
                    </Form>
                    </div>
                    
                </div>
                
            </div>
            <h1 className='lg:flex justify-center text-center lg:text-left bg-blue-light lg:pb-16 pb-16 pt-12 bg-blue-main'>© 2023
                <a className='text-red-500 ml-1 mr-1' target="_blank" href='https://zenithevolutions.com'> Zenith Evolutions.</a>
            All Rights Reserved.</h1>
        </div>
    )
}